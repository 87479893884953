import styled from 'styled-components';

export const Styled3Puntos = styled.div`

    .dropbtn {
        background: none;
        border: none;
        height: 5px;
        width: 0;
    }

    .dropbtn:last-of-type{
        height: 15px;
    }
    
    .Contenedorbtn{
        display: flex;
        flex-direction: column;
        border-radius: 2rem;
        padding: 8px;
        /* background-color: #f1f1f1; */
        color: #333;
        font-size: 16px;
        border: none;
        cursor: pointer;
        width: 1.5rem;
        align-items: center;
    }

    .dropdown {
        position: relative;
        display: inline-block;
        padding: 8px;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: flex;
    }

    .dropdown-content a:hover {
        background-color: #f1f1f1;
        cursor: pointer;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .Contenedorbtn:hover {
        background-color: #ddd;
    }

    .icon {
        margin-right: 8px;
    }

    .cancel {
        color: #ff0000;
    }

    .dropdown-content:last-of-type:last-child .MoverArriba{
        top: -7.5rem;
    }

    /*.dropdown-content:nth-last-child(2):not(:last-child) .MoverArriba{
        top: -7.5rem;
    }

    .dropdown-content:nth-last-child(3):not(:last-child){
        top: -7.5rem;
    }*/


`