import React, { useCallback, useEffect, useState } from 'react';
import { StyledHome } from "../themes/StyledHome";
import { useAuth } from '../Context/Context';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useGraficas } from '../hooks/useGraficas';
import ReactApexChart from 'react-apexcharts';
import { useCalendario } from '../hooks/useCalendario';
import Card from '../Componentes/Card';
import { useFecha } from '../hooks/useFecha';
import InputPKT1 from '../Componentes/InputPKT1';
import BotonesAcciones from '../Componentes/BotonesAcciones';
import moment from 'moment';
import Loader from '../Componentes/Loader';
import SelectPKT1 from '../Componentes/SelectPKT1';
import useUsuarios from '../hooks/useUsuarios';
import useSelect from '../hooks/useSelect';
import { useAlert } from '../hooks/useAlert';

const Home = () => {

  //Llamada al hook para mostrar el usurio en linea
  const { user } = useAuth();

  const { alertError } = useAlert();

  const { cambioSeccion } = useLocalStorage();

  const { ServicioGraficas, DatosGrafica, categoria, loading, setDatosGrafica, setCategoria } = useGraficas();

  const { fechaActualMostrar } = useFecha();

  const filtroInicio = moment().subtract(2, "week")

  const [fechaInicio, setFechaInicio] = useState(filtroInicio.format('YYYY-MM-DD'));

  const ayer = moment().subtract(1, 'days');

  const [fechaFin, setFechaFin] = useState(ayer.format('YYYY-MM-DD'));

  const { ConvertirAObjeto } = useSelect();


  const { showUsuarios, dataSelect, cargando, SoloUsuarios, nameUser, setNameUser, dataUser, idUser, setIdUser, MostrarUnicamenteUsuarios } = useUsuarios();

  const [idUsuarioSeleccionado, setIdUsuarioSeleccionado] = useState(0);

  const [Descripcion, setDescripcion] = useState("CarlosSoto");


  const [config, setConfig] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
      }
    },
  });



  const FiltrarPorFecha = () => {

    ServicioGraficas(`${process.env.REACT_APP_API_REPORTES_SOPORTE}Usuarios/HorasRegistradas?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&IdUsuario=${user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591 ? idUsuarioSeleccionado : user.idusuario}`);

    setConfig({

      options: {
        chart: {
          id: 'line'
        },
        xaxis: {
          categories: categoria?.map(item => item?.descripcion)
        }
      },
      series: [
        {
          name: 'Horas trabajadas',
          data: DatosGrafica?.map(item => item?.acumuladoMinutosTrabajados / 60)
        },
        {
          name: 'Horas estimadas',
          data: DatosGrafica?.map(item => item?.acumuladoMinutosEstimados / 60)
        }
      ]
    });


  }


  const DatosSelect = (e) => {
    // setIndex(e.target.selectedIndex);
    setIdUsuarioSeleccionado(e.target.value);
    setDescripcion(e.target.children[e.target.selectedIndex].label);
    // console.log(e.target.children[e.target.selectedIndex].label);
  }



  useEffect(() => {

    cambioSeccion("Inicio");
    // setDescripcion(nameUser);


    // console.log(dataSelect);

    console.log(DatosGrafica);

    setConfig({
      options: {
        xaxis: {
          categories: categoria?.map(item => item?.descripcion)
        }
      },
      series: [
        {
          name: 'Horas trabajadas',
          data: DatosGrafica?.map(item => item?.acumuladoMinutosTrabajados / 60)
        }
      ]
    });

  }, [DatosGrafica, categoria]);

  useEffect(() => {

    const ObtenerDatosTabla = async () => {

      if (user.idusuario == 1) {
        let FiltradoSelect = await MostrarUnicamenteUsuarios(`${process.env.REACT_APP_API_PROYECTOS}Usuarios/Usuarios`);

        setDatosGrafica(FiltradoSelect);

        ConvertirAObjeto(FiltradoSelect).then(response => {
          setCategoria(response);
        }).catch(err => alertError(err));

        // setCategoria(categoriaGrafica)
        // await ServicioGraficas(`${process.env.REACT_APP_API_REPORTES_SOPORTE}Usuarios/HorasRegistradas?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}`);
      } else {
        await ServicioGraficas(`${process.env.REACT_APP_API_REPORTES_SOPORTE}Usuarios/HorasRegistradas?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&IdUsuario=${user.idusuario}`);
      }

      // if(user.idusuario = 1){
      //   console.log("user", idUser);
      //   console.log("url", `${process.env.REACT_APP_API_REPORTES_SOPORTE}Usuarios/HorasRegistradas?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&IdUsuario=${idUser}`);
      //   await ServicioGraficas(`${process.env.REACT_APP_API_REPORTES_SOPORTE}Usuarios/HorasRegistradas?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&IdUsuario=${idUser}`);
      // }else{
      //   await ServicioGraficas(`${process.env.REACT_APP_API_REPORTES_SOPORTE}Usuarios/HorasRegistradas?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&IdUsuario=${user.idusuario}`);
      // }
    }

    ObtenerDatosTabla();
  }, []);



  return (
    <StyledHome>
      {loading || cargando ?
        <div className='PositionLoader'>
          <Loader title="Grafica" />
        </div>
        :
        <div className='ContenedorInicio'>
          {/* <div className='CardWelcome'>
          <h1>Bienvenido:</h1>
          <p>{user?.descripcion}</p>
        </div> */}

          <div className='ContenedorGraf'>
            <div className='CardGraf'>
              <div className='FiltradoGraficas'>
                {user.idusuario == 1 || user.idusuario == 34 || user.idusuario == 591 &&
                  <SelectPKT1 label={"Usuarios"} OpcionPorDefecto={Descripcion} MapeoOpciones={dataSelect} MostrarOpcionPorDefecto="none" Filtrado={(e) => DatosSelect(e)} anchoSelect={"15vw"} />
                }
                <InputPKT1 label={"Fecha Inicio"} value={fechaInicio} type={"date"} mostrar={"none"} Accion={(e) => setFechaInicio(e.target.value)} limite={fechaActualMostrar} />
                <InputPKT1 label={"Fecha Fin"} value={fechaFin} type={"date"} mostrar={"none"} Accion={(e) => setFechaFin(e.target.value)} limite={fechaActualMostrar} />
                <BotonesAcciones accion={"Filtrado"} desactivar={false} colorLetra={"#FFF"} colorFondo={"#2c3e50"} clickBoton={FiltrarPorFecha} />
              </div>
                <div className='bordeGrafica'>
                  <ReactApexChart options={config?.options} series={config?.series} type="bar" />
                </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      }
    </StyledHome>
  )
}

export default Home