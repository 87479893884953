import { createContext, useContext, useEffect, useState } from "react";
import axios from 'axios'
import config from "../config";
import Swal from "sweetalert2";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router-dom";
export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

export function AuthProvider({ children }) {

  const url = `${config.API_URL}Login/login?Origin=Interno`
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  //const [ciudadSelecionada, setsucursalselecionada] = useState(JSON.parse(localStorage.getItem('sucursal')));
  const [messageerror, setmessageerror] = useState();
  const [loading, setLoading] = useState(false);
  const [isActiveSubmenu, setActiveSubmenu] = useState(true);
  const [isActiveMenu, setisActiveMenu] = useState();
  const { cambioSeccion } = useLocalStorage();
  const [Token, setToken] = useState(JSON.parse(localStorage.getItem('token')));
  const [RefreshToken, setRefreshToken] = useState(JSON.parse(localStorage.getItem('refreshtoken')));
  const [tiempoSesion, setTiempoSession] = useState();
  const [isLogout, setIsLogout] = useState(false);
  // const navigate = useNavigate();
  // console.log(JSON.parse(localStorage.getItem("lastmenu")));
  // const [coordenadamapa, setcoordenadamapa] = useState({
  //   lng: -108.98972335988667,
  //   lat: 25.79109091258725,
  //   zoom: 13.5
  // })
  //const [coordenadamapa, setcoordenadamapa] = useState(JSON.parse(localStorage.getItem("coordenadamapa")))

  // const signup = (email, password) => {

  // };
  const login = (data, navigate) => {
    // navigate(`/home`);
    setLoading(true);
    setIsLogout(false);


    var headerLogin = {
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    // console.log(url)

    axios(headerLogin)
      .then(function (responseLogin) {

        // console.log(responseLogin)

        setToken(responseLogin.data.token);
        setRefreshToken(responseLogin.data.refreshToken);

        // console.log(JSON.stringify(responseLogin.data), "Response de login");
        var headerUsers = {
          method: 'get',
          url: `${config.API_URL}Usuario/getuser/${responseLogin.data.idusuario}`,
          headers: {}
        };

        axios(headerUsers)
          .then(function (responseUser) {
            // console.log(JSON.stringify("Response usuarios: ",responseUser));
            setLoading(false);

            setUser(responseUser.data);
            //console.log( responseUser)
            Swal.fire({
              icon: 'success',
              title: "Conectado"
            })
            navigate("/Inicio");
            cambioSeccion("Inicio");
            // var headersuc = {
            //   method: 'get',
            //   url: `${config.API_URL}Sucursal/getBranch/${responseUser.data.idfranquicia}`,
            //   headers: { }
            // };

            axios
              .then(function (responsesuc) {
                // console.log(JSON.stringify(responsesuc.data), "Response Suc");
                // setLoading(false);
                // setUser(
                //   {
                //     "id": responseLogin.data.idusuario,
                //     "descripcion": responseUser.data.descripcion,
                //     "nombre": responseUser.data.nombreusuario
                //   }
                // )
                // const datauser ={
                //   "id": responseLogin.data.idusuario,
                //   "descripcion": responseUser.data.descripcion,
                //   "nombre": responseUser.data.nombreusuario
                // }

                //setsucursalselecionada({ idSucursal: responseUser.data.idfranquicia, nombre: responsesuc.data.nombre, idCiudad: responsesuc.data.idciudad })
                // localStorage.setItem('auth', true);
                // localStorage.setItem("user", JSON.stringify(datauser))
                // localStorage.setItem("sucursal", JSON.stringify({id: responseUser.data.idfranquicia, nombre: responsesuc.data.nombre, idCiudad: responsesuc.data.idciudad}))
                // const datasu = {
                //   lng: -108.98972335988667,
                //   lat: 25.79109091258725,
                //   zoom: 10.5
                // }
                // localStorage.setItem("coordenadamapa", JSON.stringify(datasu))
                // navigate("/home")
              })
              .catch(function (error) {
                // console.log(`${config.API_URL}Sucursal/getBranch/${responseUser.data.idfranquicia}`,"URL DE SUC")
                // console.log(error,"error suc");
              });

          })
          .catch(function (error) {
            //  console.log(`${config.API_URL}Usuario/getuser/${responseLogin.data.idusuario}`,"URL DE USUARIOS")
            //  console.log(error,"Error de Usuarios");
          });

      })
      .catch(function (error) {
        // console.log(data, "REQUEST LOGIN")
        // console.log(`${config.API_URL}Login/login?Origin=Interno`,"URL LOGIN")
        // console.log(error.response.data, "error de login");
        Swal.fire({
          icon: 'error',
          title: JSON.stringify(error.response.data)
        })
        setLoading(false);
        //alert(JSON.stringify(error.response.data))
      });

    // setmessageerror(err.response.data.message)
    //setLoading(false);
  };

  const RefrescarSesion = async () => {

    let objetoRefresh = {
      "token": Token,
      "refreshtoken": RefreshToken
    }
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_WS}Login/RefreshToken`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      data: objetoRefresh
    }
    // console.log("Token: ", Token);
    // console.log("RefreshToken: ", RefreshToken);

    try {
      const respuesta = await axios(config);

      localStorage.removeItem("token");
      localStorage.removeItem("refreshtoken");

      const nuevoToken = respuesta.data.token;


      localStorage.setItem("token", JSON.stringify(nuevoToken));

      const nuevoRefreshToken = respuesta.data.refreshToken;

      localStorage.setItem("refreshtoken", JSON.stringify(nuevoRefreshToken));


      const decodedToken = jwtDecode(nuevoToken);

      // console.log("RefreshToken: ", respuesta);
      // console.log("NuevoToken decodificado: ", decodedToken);

      const fechaEmision = new Date(moment.unix(decodedToken.iat).format('YYYY-MM-DD HH:mm:ss'));
      const fechaExpiracion = new Date(moment.unix(decodedToken.exp).format('YYYY-MM-DD HH:mm:ss'));

      // console.log(fechaExpiracion.getTime());

      const tiempoCaducidadToken = fechaExpiracion.getTime() - fechaEmision.getTime();

      setTiempoSession(tiempoCaducidadToken);


      // console.log("Tiempo emitido: ", fechaEmision);
      // console.log("Tiempo expiracion: ", fechaExpiracion);
      // console.log("Tiempo Caducidad: ", tiempoCaducidadToken);

      // if (nuevoToken) {

      //   const decodedToken = jwtDecode(nuevoToken);

        // console.log("RefreshToken: ", respuesta);
        // console.log("NuevoToken decodificado: ", decodedToken);

      // }




    } catch (err) {
      logout();
    }


  }

  const logout = () => {
    const data = "Login"
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    localStorage.removeItem("sucursal");
    localStorage.removeItem("lastsubmenu");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshtoken");
    // localStorage.setItem("lastmenu", JSON.stringify(data));
    // localStorage.setItem("user", null);
    Swal.fire({
      icon: 'success',
      title: "Desconectado"
    })
    setIsLogout(true);
  };


  const handlercambioSucursal = (data) => {
    //setsucursalselecionada({ idSucursal: data.id, nombre: data.nombre })
    localStorage.setItem("sucursal", JSON.stringify({ idSucursal: data.id, nombre: data.nombre }))
    // if (data.iata === "LMM") {
    //   data = {
    //     lng: -108.98972335988667,
    //     lat: 25.79109091258725,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.iata === "GDL") {
    //   data = {
    //     lng: -103.34331011836127,
    //     lat:  20.65852985156891,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.iata === "MTY") {
    //   data = {
    //     lng: -100.31670954103768,
    //     lat: 25.68679462562785,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.iata === "CUL") {
    //   data = {
    //     lng: -107.3946021011502,
    //     lat: 24.8090748412337,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.iata === "HMO") {
    //   data = {
    //     lng: -110.97834227713562,
    //     lat: 29.08802425126585,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.iata === "CUU") {
    //   data = {
    //     lng: -106.06828656786466,
    //     lat: 28.638682252359157,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // }else if (data.iata === "QRO") {
    //   data = {
    //     lng: -100.40444662018706,
    //     lat: 20.60751362504742,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.iata === "TIJ"){
    //   data = {
    //     lng: -116.95192882916129,
    //     lat: 32.502241540900314,
    //     zoom: 10.5
    //   }
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } 
    // else if (data.id === 80) {
    //   data = {
    //     lng: -108.98972335988667,
    //     lat: 25.79109091258725,
    //     zoom: 10.5
    //   } 
    //   localStorage.setItem("coordenadamapa", JSON.stringify(data))
    // } else if (data.id ===97) {
    //     data = {
    //       lng: -100.31670954103768,
    //       lat: 25.68679462562785,
    //       zoom: 10.5
    //     }
    //     localStorage.setItem("coordenadamapa", JSON.stringify(data))
    //     setcoordenadamapa(data)
    // }
  }
  const handleractiveMenu = (data) => {
    setisActiveMenu(data);
    // console.log(data, "data")
    localStorage.setItem("lastmenu", JSON.stringify(data))
  }
  const handleractiveSubMenu = (data) => {
    setActiveSubmenu(data)

  }
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem("token", JSON.stringify(Token));
    localStorage.setItem("refreshtoken", JSON.stringify(RefreshToken));

    // localStorage.setItem("lastmenu", JSON.stringify(PaginaActual));

    // console.log(user);
    //localStorage.setItem('sucursal', JSON.stringify(ciudadSelecionada));
    // localStorage.setItem("lastmenu", JSON.stringify(isActiveMenu));
    //localStorage.setItem ("coordenadamapa", JSON.stringify(coordenadamapa))
  }, [user, Token, RefreshToken])

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        RefrescarSesion();

      }, tiempoSesion);
    }
  })

  return (
    <authContext.Provider
      value={{
        //signup,
        login,
        user,
        logout,
        loading,
        setLoading,
        isLogout,
        messageerror,
        //ciudadSelecionada,
        //setsucursalselecionada,
        //handlercambioSucursal,
        isActiveSubmenu,
        isActiveMenu,
        handleractiveMenu,
        handleractiveSubMenu,
        //coordenadamapa
      }}
    >
      {children}
    </authContext.Provider>
  );
}