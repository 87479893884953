import axios from 'axios'
import React, { useState } from 'react'
import { useAlert } from './useAlert';

export const useGraficas = () => {

  const [DatosGrafica, setDatosGrafica] = useState([]);
  const [categoria, setCategoria] = useState([]);

  const { alertError } = useAlert();


  const [loading, setLoading] = useState(false);

  const ServicioGraficas = async (url) => {

    setLoading(true);

    try {
      let RespuestaServicio = await axios.get(url);

      setLoading(false);

      const DatosServicio = RespuestaServicio.data;

      const NoDatosGrafica = DatosServicio.find((item) => item.acumuladoMinutosTrabajados !== 0);

      if(!NoDatosGrafica){
        alertError("No se encontraron registros disponibles");
      }

      const nuevaRespuesta = DatosServicio.map((item, index) => ({
        id: index,
        descripcion: item.fechaSeguimiento,
      }))

      setCategoria(nuevaRespuesta);
      setDatosGrafica(RespuestaServicio.data);
      


    } catch (error) {
      setLoading(false);
      alertError(error);

    }
  }

  const ServicioGraficaFiltradoUsuarios = async (url) => {

    try {
      let RespuestaServicio = await axios.get(url);

      setLoading(false);

      return RespuestaServicio.data

    } catch (error) {
      setLoading(false);
      console.log(error);

    }
  }


  return {
    ServicioGraficas,
    ServicioGraficaFiltradoUsuarios,
    DatosGrafica,
    setDatosGrafica,
    categoria,
    setCategoria,
    loading
  }
}
