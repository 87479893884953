import React, { useEffect, useState } from 'react'
import Opciones from '../assets/svg/opciones.svg'
import { StyledActividades } from '../themes/StyledActividades'
import add from '../assets/svg/add.svg'
import Modal from '../Componentes/Modal'
import { StyledAgregarAct } from '../themes/StyledAgregarAct'
import flechaAbajo from "../assets/svg/abajo.svg"
import seguimiento from '../assets/img/tiempo-rapido.png'
import Registro from '../Componentes/Registro'
import Paginador from '../Componentes/Paginador'
import { useShowDataTable } from '../hooks/useShowDataTable'
import { usePaginador } from '../hooks/usePaginador'
import Loader from '../Componentes/Loader'
import { useGuardar } from '../hooks/useGuardar'
import Buscar from '../assets/svg/buscar.svg'
import { useFecha } from '../hooks/useFecha'
import { useNavigate } from 'react-router-dom'
import { useShowNameStatus } from '../hooks/useShowNameStatus'
import Editar from "../assets/svg/editar.svg"
import Swal from 'sweetalert2'
import Eliminar from "../assets/svg/Eliminar.svg"
import { useEliminar } from '../hooks/useEliminar'
import { useCompararObjetos } from '../hooks/useCompararObjetos'
import { useConvertirHora } from '../hooks/useConvertirHora'
import { useAuth } from '../Context/Context'
import { useShowProyectos } from '../hooks/useProyectos'
import useUsuarios from '../hooks/useUsuarios'
import SelectPKT1 from '../Componentes/SelectPKT1'
import useSelect from '../hooks/useSelect'
import InputPKT1 from '../Componentes/InputPKT1'
import TextAreaPKT1 from '../Componentes/TextAreaPKT1'
import BotonesAcciones from '../Componentes/BotonesAcciones'
import Agregar from '../Componentes/Agregar'
import { useAlert } from '../hooks/useAlert'
import { useNumero } from '../hooks/useNumero'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useValidacion } from '../hooks/useValidacion'
import AccionesTabla from '../Componentes/AccionesTabla'



const ListadoActividades = (props) => {


    const [showSelect, setShowSelect] = useState(false);
    const [showModalSelect, setShowModalSelect] = useState(false);
    const [showFiltroUser, setShowFiltroUser] = useState(false);

    const [filtroEstatus, setFiltroEstatus] = useState("Todos");
    const [usuarioAsignado, setUsuarioAsignado] = useState("Admin");
    const [filtroUser, setFiltroUser] = useState("Todos");
    const [cambiarBoton, setCambiarBoton] = useState(false);
    const [buscar, setBuscar] = useState("");
    const [ids, setIds] = useState(0);
    let startDate
    let estimatedminutes
    const [showMessage, setShowMessage] = useState(false);
    const [mostrarBotones, setMostrarBotones] = useState("");
    const [idFilterEstatus, setIdFilterEstatus] = useState(6);
    const [idFiltroUsuarios, setIdFiltroUsuarios] = useState(0);

    const [MostrarMenuOpciones, setMostrarMenuOpciones] = useState(false);

    const [idSelectUser, setIdSelectUser] = useState(0);


    const [pagina, setPagina] = useState(1);

    const navigate = useNavigate();

    const [actividad, setActividad] = useState({});



    //Llamada al hook para mostrar el usurio en linea
    const { Usuario, PaginaActual, setPaginaActual, user } = useAuth();

    const { cambioSeccion } = useLocalStorage();




    /* *********************************************** */



    const { showUsuarios, nameUser, dataUser, idUser, dataSelect } = useUsuarios();

    const [showNewActivity, setShowNewActivity] = useState(false);


    //Preparando el objeto para enviarlo al seguimiento

    //Valor del select
    const [valorSelect, setValorSelect] = useState(10);
    const [showRegistro, setShowRegistro] = useState(false);

    //Haciendo la llamada al custom hook que regresa el listado de actividades

    const { actividadCancelada, setActividadCancelada, setIdUsuario, idusuario, nombreUsuario, setNombreUsuario, setIdActivity, setActivityName, setActivityDescription, setHoras, setStatus, cambiarFecha, setCambiarFecha, idActivity, activityName, activitydescription, horas, estatus, data, loading, setLoading, showEditData, editData, error, limitePaginas, showData, idProyectoSeleccionado, setIdProyectoSeleccionado, proyectoSeleccionado, setProyectoSeleccionado } = useShowDataTable();

    //Haciendo la llamada al custom hook que retorna el numero de pagina recibidas del sevicio

    const { ultimaPagina, setUltimaPagina, obtenerUltimaPagina } = usePaginador();


    //Haciendo la llamada la custom hook que se encarga de hacer la llamada al servicio de insercion

    const { guardar, guardarCambios, showModalAgregar, setShowModalAgregar } = useGuardar();

    const { formatFecha, formatInsertFecha, formatShowDefaultFecha, formatShowFecha, fechaActualMostrar, formatEditFecha } = useFecha();

    //Llamada a un hook para converir a numero

    const [VariableNumerica, ConvertirVariable] = useNumero();



    //fecha de formulario de seguimiento de actividades
    const [seguimientoFecha, setSeguimientoFecha] = useState(fechaActualMostrar);

    const { mostrarEstatus } = useShowNameStatus();

    const { cancelActivity } = useEliminar();

    const { datosEditados, setDatosEditados, CompararObjetos } = useCompararObjetos();

    const { showHoras } = useConvertirHora();

    const [Filtros, setFiltros] = useState({
        "filtros": [
            0
        ]
    });

    const { mostrarProyectos, showDatosPoryectos, FormularioProyectos } = useShowProyectos();


    const [showSelectProyectos, setShowSelectProyectos] = useState(false);

    const [index, setIndex] = useState();

    const [Descripcion, setDescripcion] = useState("Todos");

    // const { ConvertirAObjeto, objetoSelect } = useSelect();

    const FiltroEstatus = [
        {
            "id": 0,
            "descripcion": "Todos"
        },
        {
            "id": 1,
            "descripcion": "Asignados"
        },
        {
            "id": 2,
            "descripcion": "En Proceso"
        },
        {
            "id": 3,
            "descripcion": "Pendientes",
        },
        {
            "id": 4,
            "descripcion": "Terminado",
        },
        {
            "id": 5,
            "descripcion": "Cancelado"
        },
        {
            "id": 6,
            "descripcion": "SinConcluir"
        }
    ];

    const FiltroRegistros = [
        {
            "id": 10,
            "descripcion": 10
        },
        {
            "id": 25,
            "descripcion": 25
        },
        {
            "id": 100,
            "descripcion": 100
        }
    ];

    const FormularioEstatus = [
        {
            "id": 0,
            "descripcion": "Asignado"
        },
        {
            "id": 1,
            "descripcion": "En Proceso"
        },
        {
            "id": 2,
            "descripcion": "Pendiente",
        },
        {
            "id": 3,
            "descripcion": "Terminado",
        },
        {
            "id": 4,
            "descripcion": "Cancelado"
        }
    ];

    // const [VerificadorCampos, setVerificadorCampos] = useState({
    //     usuario: false,
    //     horas: false,
    //     proyecto: false,
    //     titulo: false,
    //     descripcion: false,
    //     fecha: false
    // });

    let ObjetoValidador = {}

    const { alertError } = useAlert();

    const [NumeroRegistros, setNumeroRegistros] = useState(10);

    const [desactivarBoton, setDesactivarBoton] = useState(false);

    const { validadorObjetos, VerificadorCampos, LimpiadorObjetoValidador } = useValidacion();


    /************************************************************************************************** */

    useEffect(() => {
        // setLoading(true);
        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
            showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?&Filtro=${idFilterEstatus}&Pagina=${pagina}&RegistrosPorPagina=${valorSelect}`);
            obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?&Filtro=${idFilterEstatus}&Pagina=${pagina}&RegistrosPorPagina=${valorSelect}`);
            showUsuarios(`${process.env.REACT_APP_API_PROYECTOS}Usuarios/Usuarios`);
        } else {
            showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=${pagina}&RegistrosPorPagina=${valorSelect}`);
            obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${valorSelect}`);
        }

        showDatosPoryectos(`${process.env.REACT_APP_API_PROYECTOS}Proyectos/Paginador?Buscar=&Pagina=1&RegistrosPorPagina=100`, Filtros);

        cambioSeccion("Bitacora");

        // setLoading(false);
    }, []);


    //funcion para mostrar  y ocultar opciones con los 3 puntos de la tabla
    const mostrarOpciones = (id, estatusC) => {

        if (id === ids) {
            setIds("");

        } else {
            setIds(id);
        }

        if (estatusC === 4) {
            setActividadCancelada(true);
        } else {
            setActividadCancelada(false);
        }
    }

    const OcultarOpciones = (id) => {
        setIds("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setNombreUsuario(Usuario.idusuario);

        setDesactivarBoton(true);

        setLoading(true);
        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
            let ValidadorDatosActividades = {
                "usuario": idSelectUser,
                "horas": horas,
                "proyecto": idProyectoSeleccionado,
                "titulo": activityName,
                "descripcion": activitydescription,
                "fecha": cambiarFecha
            }



            // for (let propiedad in ValidadorDatosActividades) {
            //     if (ValidadorDatosActividades[propiedad] == "" || ValidadorDatosActividades[propiedad] == 0 ) {
            //         ObjetoValidador = {
            //             [propiedad]: true
            //         }
            //         VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
            //         setVerificadorCampos({ ...VerificadorCampos, [propiedad]: true })
            //     } else {
            //         ObjetoValidador = {
            //             [propiedad]: false
            //         }
            //         VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
            //         setVerificadorCampos({ ...VerificadorCampos, [propiedad]: false })
            //     }
            // }

            //Llamada a la funcion del hook validacion
            validadorObjetos(ValidadorDatosActividades);
        } else {
            let ValidadorDatosActividades = {
                "usuario": nombreUsuario,
                "horas": horas,
                "proyecto": idProyectoSeleccionado,
                "titulo": activityName,
                "descripcion": activitydescription,
                "fecha": cambiarFecha
            }

            //Llamada a la funcion del hook validacion
            validadorObjetos(ValidadorDatosActividades);

            // for (let propiedad in ValidadorDatosActividades) {
            //     if (ValidadorDatosActividades[propiedad] == "" || ValidadorDatosActividades[propiedad] == undefined ) {
            //         ObjetoValidador = {
            //             [propiedad]: true
            //         }
            //         VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
            //         setVerificadorCampos({ ...VerificadorCampos, [propiedad]: true })
            // } else {
            //     ObjetoValidador = {
            //         [propiedad]: false
            //     }
            //     VerificadorCampos[propiedad] = ObjetoValidador[propiedad]
            //     setVerificadorCampos({ ...VerificadorCampos, [propiedad]: false })
            // }

            // }
        }




        if (!VerificadorCampos.usuario && !VerificadorCampos.horas && !VerificadorCampos.proyecto && !VerificadorCampos.titulo && !VerificadorCampos.descripcion && !VerificadorCampos.fecha) {

            estimatedminutes = horas * 60;
            if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
                if (cambiarFecha === fechaActualMostrar) {
                    //armar el objeto para agregar una nueva actividad
                    const formularioActividades = {
                        "idUsuario": idSelectUser,
                        "idProyecto": idProyectoSeleccionado,
                        "nombreActividad": activityName,
                        "descripcionActividad": activitydescription,
                        "minutosEstimados": estimatedminutes,
                        "estatus": estatus,
                        "fechaInicio": cambiarFecha
                    };
                    // console.log(formularioActividades);
                    //llamada al servicio para insertar la actividad
                    await guardar(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Agregar`, formularioActividades);
                    setShowMessage(false);
                    //muestra los datos actualizados
                    await limpiarModal();
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Pagina=1&RegistrosPorPagina=${NumeroRegistros}&Filtro=${idFilterEstatus}&Usuario=${idFiltroUsuarios}`);
                } else {
                    //armar el objeto para agregar una nueva actividad
                    const formularioActividades = {
                        "idUsuario": idSelectUser,
                        "idProyecto": idProyectoSeleccionado,
                        "nombreActividad": activityName,
                        "descripcionActividad": activitydescription,
                        "minutosEstimados": estimatedminutes,
                        "estatus": estatus,
                        "fechaInicio": cambiarFecha
                    };
                    //llamada al servicio para insertar la actividad
                    await guardar(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Agregar`, formularioActividades);
                    setShowMessage(false);
                    //muestra los datos actualizados
                    await limpiarModal();
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Pagina=1&RegistrosPorPagina=${NumeroRegistros}&Filtro=${idFilterEstatus}&Usuario=${idFiltroUsuarios}`);
                }
            } else {
                if (cambiarFecha === fechaActualMostrar) {
                    //armar el objeto para agregar una nueva actividad
                    const formularioActividades = {
                        "idUsuario": idusuario,
                        "idProyecto": idProyectoSeleccionado,
                        "nombreActividad": activityName,
                        "descripcionActividad": activitydescription,
                        "minutosEstimados": estimatedminutes,
                        "estatus": estatus,
                        "fechaInicio": cambiarFecha
                    };
                    //console.log(formularioActividades);
                    //llamada al servicio para insertar la actividad
                    await guardar(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Agregar`, formularioActividades);
                    setShowMessage(false);
                    //muestra los datos actualizados
                    await limpiarModal();
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Usuario=${idusuario}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}&Filtro=${idFilterEstatus}`);
                } else {
                    //armar el objeto para agregar una nueva actividad
                    const formularioActividades = {
                        "idUsuario": idusuario,
                        "idProyecto": idProyectoSeleccionado,
                        "nombreActividad": activityName,
                        "descripcionActividad": activitydescription,
                        "minutosEstimados": estimatedminutes,
                        "estatus": estatus,
                        "fechaInicio": cambiarFecha
                    };
                    //llamada al servicio para insertar la actividad
                    await guardar(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Agregar`, formularioActividades);
                    setShowMessage(false);
                    //muestra los datos actualizados
                    await limpiarModal();
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Usuario=${idusuario}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}&Filtro=${idFilterEstatus}`);
                }
            }
        } else {
            alertError("Favor de llenar todos los campos");
            setDesactivarBoton(false);
        }
        setLoading(false);
    }

    const limpiarModal = async () => {
        setActivityName("");
        setActivityDescription("");
        setHoras("");
        setCambiarFecha(fechaActualMostrar);
        setProyectoSeleccionado("Seleccionar proyecto...");
        setDesactivarBoton(false);

        // for (let propiedad in VerificadorCampos) {
        //     setVerificadorCampos({ [propiedad]: false });
        // }
    }

    const nextPagina = async () => {
        if (ultimaPagina == 1) {
            setPagina(1);
        } else if (pagina < ultimaPagina) {
            setPagina(pagina + 1);
            if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
                if (idFiltroUsuarios > 0) {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=${pagina + 1}&RegistrosPorPagina=${NumeroRegistros}`);
                } else {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Pagina=${pagina + 1}&Filtro=${idFilterEstatus}&RegistrosPorPagina=${NumeroRegistros}`);
                }
            } else {
                await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=${pagina + 1}&RegistrosPorPagina=${NumeroRegistros}`);

            }
        }
    }

    const prevPagina = async () => {
        if (pagina > 1) {
            setPagina(pagina - 1);
            if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
                if (idFiltroUsuarios > 0) {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=${pagina - 1}&RegistrosPorPagina=${NumeroRegistros}`);
                } else {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Pagina=${pagina - 1}&Filtro=${idFilterEstatus}&RegistrosPorPagina=${NumeroRegistros}`);
                }
            } else {
                await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=${pagina - 1}&RegistrosPorPagina=${NumeroRegistros}`);
            }
        }
    }

    const firstPage = async () => {
        if (pagina != 1) {
            setPagina(1);
            if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
                if (idFiltroUsuarios > 0) {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Pagina=1&Filtro=${idFilterEstatus}&RegistrosPorPagina=${NumeroRegistros}`);
                } else {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Pagina=1&Filtro=${idFilterEstatus}&RegistrosPorPagina=${NumeroRegistros}`);
                }
            } else {
                await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            }
        }
    }

    const lastPage = async () => {
        if (ultimaPagina != 1) {
            setPagina(ultimaPagina);
            if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
                if (idFiltroUsuarios > 0) {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=${ultimaPagina}&RegistrosPorPagina=${NumeroRegistros}`);
                } else {
                    await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Pagina=${ultimaPagina}&Filtro=${idFilterEstatus}&RegistrosPorPagina=${NumeroRegistros}`);
                }
            } else {
                await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=${ultimaPagina}&RegistrosPorPagina=${NumeroRegistros}`);
            }
        }
    }

    const cambiarValorSelect = async (e) => {
        setPagina(1);
        let numeroPaginas = e.target.value;
        setNumeroRegistros(e.target.value);
        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
            if (idFiltroUsuarios > 0) {
                await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${numeroPaginas}`);
                obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${numeroPaginas}`);
            } else {
                await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${numeroPaginas}`);
                obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Pagina=1&RegistrosPorPagina=${numeroPaginas}`);
            }
        } else {
            await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${numeroPaginas}`);
            obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${numeroPaginas}`);
        }

    }

    const handleSearch = async () => {
        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
            await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Pagina=1&RegistrosPorPagina=10`);
        } else {
            await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Pagina=1&RegistrosPorPagina=10`);
        }
    }

    const handleEstatus = (e) => {

        setLoading(true);

        setPagina(1);
        let idEstatus = e.target.value;

        setIdFilterEstatus(e.target.value);

        // setNumeroRegistros(10);

        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
            if (idFiltroUsuarios == 0) {
                showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Filtro=${idEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Filtro=${idEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            } else {
                showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Filtro=${idEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idFiltroUsuarios}&Filtro=${idEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            }
        } else {
            showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${idusuario}&Filtro=${idEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
        }

        setLoading(false);

        // console.log(ultimaPagina);
    }



    const cerrarModalAgregar = () => {
        setShowModalAgregar(false);
        setShowNewActivity(false);
        limpiarModal();
        LimpiadorObjetoValidador();
    }



    const CancelarActividad = async (id, nombreActividad) => {

        Swal.fire({
            title: 'Estas seguro que deseas cancelar la actividad?',
            text: `${id}.- ${nombreActividad}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si'
        }).then(async(result) => {
            if (result.isConfirmed) {
                mostrarOpciones(id);

                let sendCancelarActividad = { estatus: 4 };

                await guardarCambios(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Actualizar/${id}`, sendCancelarActividad);

                await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Usuario=${idusuario}&Pagina=1&RegistrosPorPagina=10`);
            }
        });
        
    }


    const EditarActividad = async (id, e) => {

        setMostrarBotones("Editar");
        mostrarOpciones(id);

        await showEditData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/${id}`);
        setShowModalAgregar(true);

        // console.log(proyectoSeleccionado)


    }

    const saveChanges = async () => {

        let copiaEditData

        setShowModalAgregar(false);

        let ffecha = formatFecha(editData?.fechaInicio);



        //Creamos un arreglo nuevo para mandarlo al servicio
        let datosModificados = {};

        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {

            //Hacemos una copia del arreglo original
            copiaEditData = {
                ...editData,
                fechaInicio: ffecha,
                minutosEstimados: editData?.tiempo.minutosEstimados,
                idUsuario: idSelectUser
            }

            editData.idUsuario = idSelectUser;
            editData.idProyecto = idProyectoSeleccionado;
            editData.nombreActividad = activityName;
            editData.descripcionActividad = activitydescription;
            editData.fechaInicio = cambiarFecha;
            editData.minutosEstimados = horas * 60;

        } else {

            //Hacemos una copia del arreglo original
            copiaEditData = {
                ...editData,
                fechaInicio: ffecha,
                minutosEstimados: editData?.tiempo.minutosEstimados,
                idUsuario: idusuario
            }

            //Actualizamos los propiedades del objeto original
            editData.idUsuario = idusuario;
            editData.idProyecto = idProyectoSeleccionado;
            editData.nombreActividad = activityName;
            editData.descripcionActividad = activitydescription;
            editData.fechaInicio = cambiarFecha;
            editData.minutosEstimados = horas * 60;
        }


        //startDate = formatFecha(cambiarFecha);

        for (let propiedad in editData) {
            if (editData[propiedad] !== copiaEditData[propiedad]) {
                datosModificados[propiedad] = editData[propiedad];
                // console.log(datosModificados);
                //console.log(editData[propiedad]);
                //console.log(copiaEditData[propiedad]);

            }
        }

        await guardarCambios(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Actualizar/${idActivity}`, datosModificados);

        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
            await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Pagina=${pagina}&RegistrosPorPagina=${NumeroRegistros}&Filtro=${idFilterEstatus}`);
        } else {
            await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Usuario=${idusuario}&Pagina=${pagina}&RegistrosPorPagina=${NumeroRegistros}&Filtro=${idFilterEstatus}`);
        }

    }

    const cerrarModal = () => {
        setShowModalAgregar(false);
    }

    const mostrarModal = (e) => {
        limpiarModal();
        setMostrarBotones("Guardar");
        setShowModalAgregar(true);
        setStatus(0);
    }

    //seccion admin

    const filtrarUsuario = async (e) => {

        setIdFiltroUsuarios(e.target.value);
        let usuario = e.target.value;

        setIndex(e.target.selectedIndex);
        setDescripcion(e.target.children[e.target.selectedIndex].text);

        // setNumeroRegistros(10);

        if (usuario == 0) {
            await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
        } else {
            setShowFiltroUser(false);
            await showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${usuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${buscar}&Usuario=${usuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
        }

    }

    const ocultarListadoSelect = () => {
        setShowFiltroUser(false);
        setShowModalSelect(false);
        setShowSelect(false);
        setShowRegistro(false);
    }


    const SelectProyectos = (idProyecto, nombreProyecto) => {

        ocultarListadoProyectos();

        setProyectoSeleccionado(nombreProyecto);
        setIdProyectoSeleccionado(idProyecto);

    }

    const ocultarListadoProyectos = () => {
        setShowSelectProyectos(false);
    }


    const BuscarActividades = (e) => {
        let actividadPorBuscar = e.target.value;
        setBuscar(actividadPorBuscar);
        if (user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591) {
            if (actividadPorBuscar.length >= 3) {
                setLoading(true);
                if (idFiltroUsuarios > 0) {
                    showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                    obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                } else {
                    setLoading(true);
                    showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Filtro=${idFilterEstatus}&Pagina=1&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                    obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                }
            }else{
                if (idFiltroUsuarios > 0) {
                    showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                    obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Usuario=${idFiltroUsuarios}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                } else {
                    showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Filtro=${idFilterEstatus}&Pagina=1&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                    obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                }
            }
            //     // if (actividadPorBuscar === ""){
            //     //     showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Pagina=1&RegistrosPorPagina=10`);
            //     // }
        } else {
            if (actividadPorBuscar.length >= 3) {
                
                setLoading(true);
                //     // if (actividadPorBuscar === ""){
                //     //     showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Usuario=${idusuario}&Pagina=1&RegistrosPorPagina=10`);
                //     // }else{
                showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=${actividadPorBuscar}&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            }else{
                showData(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
                obtenerUltimaPagina(`${process.env.REACT_APP_API_PROYECTOS}Actividades/Paginador?Buscar=&Usuario=${idusuario}&Filtro=${idFilterEstatus}&Pagina=1&RegistrosPorPagina=${NumeroRegistros}`);
            }
        }
        setLoading(false);
        // }
    }

    const SeccionSeguimiento = (id, proyecto, fechaRegistro, nombreActividad, usuario, fechaInicio, estatus) => {
        cambioSeccion(nombreActividad);
        navigate(`/listadoSeguimiento/${id}`,
            {
                state: {
                    id: id,
                    proyecto: proyecto,
                    fechaRegistro: fechaRegistro,
                    nombre: nombreActividad,
                    usuario: usuario,
                    fechaActividad: fechaInicio,
                    estatusActividad: estatus
                }
            })
    }

    
    const ArrayOpciones = [
        {
            icono: "🕒",
            descripcion: "Seguimiento",
            Function: SeccionSeguimiento
        },
        {
            icono: "✏️",
            descripcion: "Editar",
            Function: EditarActividad
        },
        {
            icono: "❌",
            descripcion: "Cancelar",
            Function: CancelarActividad
        }
    ]


    return (
        <>
            <StyledActividades>
                <div className='scroll'>
                    <div className='bodyRegistroActividades'>
                        <div className='acomodo'>
                            <div className='ContenedorFiltros'>
                                <InputPKT1 label={"Buscar"} value={buscar} placeholder={"Actividad"} type={"search"} mostrar={true} background={"rgb(58, 147, 56)"} BtnImg={Buscar} Accion={(e) => BuscarActividades(e)} />
                                {user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591 &&
                                    <SelectPKT1 label={"Usuarios"} OpcionPorDefecto={Descripcion} MapeoOpciones={dataSelect} MostrarOpcionPorDefecto="none" Filtrado={(e) => filtrarUsuario(e)} value={0} />
                                }
                                <SelectPKT1 label={"Estatus"} OpcionPorDefecto={FiltroEstatus[idFilterEstatus].descripcion} MapeoOpciones={FiltroEstatus} Filtrado={handleEstatus} MostrarOpcionPorDefecto="none" />
                            </div>
                            <div className='ContenedorAgregar'>
                                <Agregar IconoAgregar={add} Accion={mostrarModal} />
                            </div>
                        </div>
                        <div className='table'>
                            <div className='headerTable'>
                                <p className='MId'>ID</p>
                                <p className='MProyecto'>Proyecto asigando</p>
                                <p className='MRegistro'>Fecha registro</p>
                                <p className='MActividad'>Actividad</p>
                                <p className='MUsuario'>Usuario</p>
                                <p className='MFechaActividad'>Fecha actividad</p>
                                <p className='MEstatus'>Estatus</p>
                                <p className='MAcciones'></p>
                            </div>
                            <div className='bodyTable'>
                                {loading ?
                                    <div className='PositionLoader'>
                                        <Loader title="Actividades"/>
                                    </div> :
                                    data != null ?
                                        (
                                            data?.map((item) => (
                                                <div className='col' key={item.id} onMouseLeave={() => OcultarOpciones()}>
                                                    <p className='DId'>{item.id}</p>
                                                    <p className='DProyecto'>{item.nombreProyecto}</p>
                                                    <p className='fecha DRegistro'>{formatShowFecha(item.fecha)}</p>
                                                    <p className='DActividad'>{item.nombreActividad} 
                                                        { item.nombreActividad.length > 100 &&
                                                            <div className='tooltip'>
                                                                {item.nombreActividad}
                                                            </div>
                                                        }
                                                    </p>
                                                    <p className='DUsuario'>{item.nombreUsuario}</p>
                                                    <p className='fecha DFechaActividad'>{formatShowFecha(item.fechaInicio)}</p>
                                                    <p className={item.estatus === 0 ? 'EstatusAsignado DEstatus' : item.estatus === 1 ? 'EstatusEnproceso DEstatus' : item.estatus === 2 ? 'EstatusPendientes DEstatus' : item.estatus === 3 ? 'EstatusTerminado DEstatus' : item.estatus === 4 ? 'EstatusCancelado DEstatus' : 'DEstatus'}>{mostrarEstatus(item.estatus)}</p>
                                                    <div className='ContenedorOpciones'>
                                                        <div className='seleccionarOpciones'>
                                                            {/* <AccionesTabla 
                                                                submenuAccionesTabla={ArrayOpciones}
                                                                accion={(e) => e.target.text == "🕒Seguimiento" ? SeccionSeguimiento(
                                                                    item.id,
                                                                    item.nombreProyecto,
                                                                    item.fecha,
                                                                    item.nombreActividad,
                                                                    item.nombreUsuario,
                                                                    item.fechaInicio,
                                                                    item.estatus
                                                                ) : e.target.text == "✏️Editar" ? EditarActividad(item.id, e) : e.target.text == "❌Cancelar" && Swal.fire({
                                                                    title: 'Estas seguro que deseas cancelar la actividad?',
                                                                    text: `${item.id}.- ${item.nombreActividad}`,
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Si'
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        CancelarActividad(item.id);
                                                                    }
                                                                }) }/> */}
                                                            {/* <AccionesTabla 
                                                                submenuAccionesTabla={ArrayOpciones}
                                                                renglon={item} /> */}
                                                            <img className={ids === item.id ? `seleccionarOpcionesRegistro ContendorOpciones` : `ContendorOpciones`} src={Opciones} alt="Opciones" onClick={() => mostrarOpciones(item.id, item.estatus)} />
                                                        </div>
                                                        <div className='backOpciones' onMouseLeave={() => OcultarOpciones()}>
                                                            <div className={data?.length < 7 ? "ListadoOpciones opciones" : "MoverArriba opciones"} id='ListadoSeguimiento' style={ids === item.id ? { display: "block" } : { display: 'none' }}  >
                                                                <div className='opcionSeguimiento'
                                                                    onClick={() => SeccionSeguimiento(
                                                                        item.id,
                                                                        item.nombreProyecto,
                                                                        item.fecha,
                                                                        item.nombreActividad,
                                                                        item.nombreUsuario,
                                                                        item.fechaInicio,
                                                                        item.estatus
                                                                    )} >
                                                                    {/* onClick={() => console.log(item.id)} > */}
                                                                    <div className='iconoSeguimiento'>
                                                                        <img src={seguimiento} alt="IconoSeguimiento" />
                                                                    </div>
                                                                    <p>Seguimiento</p>
                                                                </div>
                                                                <div className='opcionSeguimiento' onClick={(e) => EditarActividad(item.id, e)} >
                                                                    <div className='iconoEditar'>
                                                                        <img src={Editar} alt="IconoEditar" />
                                                                    </div>
                                                                    <p>Editar</p>
                                                                </div>
                                                                <div className={actividadCancelada ? "actividadesCanceladas" : "opcionSeguimiento"} onClick={() =>
                                                                    Swal.fire({
                                                                        title: 'Estas seguro que deseas cancelar la actividad?',
                                                                        text: `${item.id}.- ${item.nombreActividad}`,
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: 'Si'
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            CancelarActividad(item.id);
                                                                        }
                                                                    })} >
                                                                    <div className='iconoEliminar'>
                                                                        <img src={Eliminar} alt="IconoEliminar" />
                                                                    </div>
                                                                    <p disabled={actividadCancelada ? true : false}>Cancelar</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))) : (<div className='nullData'> <p> No se encontraron datos </p></div>)}
                            </div>
                        </div>
                        <div className='cambioRegistros'>
                            <div className='Registro'>
                                <SelectPKT1 label={"Registros:"} OpcionPorDefecto={NumeroRegistros} MapeoOpciones={FiltroRegistros} Filtrado={cambiarValorSelect} MostrarOpcionPorDefecto="none" />
                            </div>
                            <div className='Paginador'>
                                <Paginador paginaActual={pagina > ultimaPagina ? ultimaPagina : pagina} next={nextPagina} ultimaPagina={ultimaPagina} lastPage={lastPage} firstPage={firstPage} prevPagina={prevPagina} />
                            </div>
                        </div>
                    </div>
                </div>
            </StyledActividades>

            <Modal estado={showModalAgregar} cambiarEstado={() => cerrarModalAgregar()} titulo={estatus == 0 ? "Agregar Actividad" : estatus == 4 ? "Actividad" : "Editar Actividad"}>
                <StyledAgregarAct>
                    <div className='AgregarActividades'>
                        <div className='cardForm'>
                            <form className='BodyForm'>
                                <div className='ContenedorEncabezadoFormulario'>
                                    {user.idusuario === 1 || user.idusuario === 34 || user.idusuario === 591 && mostrarBotones !== "Editar" ?
                                        <div className='ContenedorUsuario'>
                                            <SelectPKT1 CampoValidado={VerificadorCampos.usuario} label={"Usuarios"} OpcionPorDefecto={"Seleccionar"} MapeoOpciones={dataSelect} MostrarOpcionPorDefecto="block" Filtrado={(e) => setIdSelectUser(e.target.value)} value={idSelectUser} desactivar={estatus === 4 ? true : false} />
                                        </div>
                                        :
                                        <div className='ContenedorUsuario'>
                                            <InputPKT1 label={"Usuario"} value={nombreUsuario} type={"text"} mostrar={"none"} desactivar={true} />
                                        </div>
                                    }
                                    <div className='ContenedorHoras'>
                                        <InputPKT1 label={"Horas"} placeholder={"0"} type={"number"} mostrar={"none"} CampoValidado={VerificadorCampos.horas} value={horas} Accion={(e) => setHoras(e.target.value)} desactivar={estatus === 4 ? true : false} />
                                    </div>

                                    {estatus !== 4 ?
                                        <SelectPKT1 label={"Proyectos"} OpcionPorDefecto={proyectoSeleccionado} CampoValidado={VerificadorCampos.proyecto} MapeoOpciones={FormularioProyectos} MostrarOpcionPorDefecto="none" Filtrado={(e) => setIdProyectoSeleccionado(e.target.value)} value={idProyectoSeleccionado} />
                                        :
                                        <InputPKT1 label={"Proyecto asignado"} placeholder={"Actividad"} type={"text"} mostrar={"none"} value={proyectoSeleccionado} desactivar={true} />
                                    }
                                </div>
                                <div className='ContenedorTitulo'>
                                    <InputPKT1 label={"Título"} placeholder={"Actividad"} type={"text"} mostrar={"none"} CampoValidado={VerificadorCampos.titulo} value={activityName} Accion={(e) => setActivityName(e.target.value)} size={150} desactivar={estatus === 4 ? true : false} />
                                </div>

                                <div className='descActividad'>
                                    <TextAreaPKT1 label={"Descripción"} placeholder={"Descripción de la actividad"} CampoValidado={VerificadorCampos.descripcion} size={500} value={activitydescription} onchange={(e) => setActivityDescription(e.target.value)} desactivar={estatus === 4 ? true : false} />
                                </div>


                                <div className='ContenedorFooterFormulario'>
                                    <InputPKT1 label={"Fecha"} type={"date"} mostrar={"none"} value={cambiarFecha} CampoValidado={VerificadorCampos.fecha} Accion={(e) => setCambiarFecha(e.target.value)} desactivar={estatus === 4 ? true : false} />
                                    <InputPKT1 label={"Estatus"} value={mostrarEstatus(estatus)} type={"text"} mostrar={"none"} desactivar={true} />
                                </div>
                                {estatus === 4 ?
                                    <div className='butons'>
                                        <BotonesAcciones accion={"Cerrar"} colorFondo={"#D60000"} colorLetra={"#FFF"} clickBoton={cerrarModal} />
                                    </div> : mostrarBotones !== "Editar" ?
                                    <div className='butons'>
                                        <BotonesAcciones accion={"Guardar"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={handleSubmit} desactivar={desactivarBoton} />
                                    </div> :
                                    <div className='butons'>
                                        <BotonesAcciones accion={"GuardarCambios"} colorFondo={"#3BAF29"} colorLetra={"#FFF"} clickBoton={saveChanges} />
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </StyledAgregarAct>
            </Modal>
        </>
    )
}

export default ListadoActividades